/* Styles */
import cx from "classnames"
import home from "./../styles/home.module.css"
import "./../styles/global.css";

/* Components */
import { Navbar } from "../components/Navigation";
import {Footer, Copyrigth } from "../components/Footer";
import { HomeHeader } from "../components/Header";
import { HeaderCarousel, HomeCarousel } from "../components/Carousel.jsx"

/* Effects */
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
    
    { /* Entradas de texto */ }
    const lifestyle__title = useRef(null);
    const location__title = useRef(null);
    const location__subtitle = useRef(null);
    const condos__title = useRef(null);
    const condos__subtitle = useRef(null);

    useEffect(() => {
        gsap.from(lifestyle__title.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.lifestyle__title' });
    }, []);
    useEffect(() => {
        gsap.from(location__title.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.location__title' });
    }, []);
    useEffect(() => {
        gsap.from(location__subtitle.current, { duration: 1.5, y: 30, opacity: 0, delay: 0.2, scrollTrigger: '.location__subtitle' });
    }, []);
    useEffect(() => {
        gsap.from(condos__title.current, { duration: 1.5, y: 30, opacity: 0, scrollTrigger: '.condos__title' });
    }, []);
    useEffect(() => {
        gsap.from(condos__subtitle.current, { duration: 1.5, y: 30, opacity: 0, delay: 0.2, scrollTrigger: '.condos__subtitle' });
    }, []);

    { /* Entrada de bloques por izquierda */ }
    const fromLeft = useRef(null);
    const fromLeft2 = useRef(null);
    
    useEffect(() => {
        gsap.to(fromLeft.current, { duration: 1.5, transform: 'translateX(0)', opacity: 1, ease: 'circ.out', scrollTrigger: '.fromLeft' });
    }, []);
    useEffect(() => {
        gsap.to(fromLeft2.current, { duration: 1.5, transform: 'translateX(0)', opacity: 1, ease: 'circ.out', scrollTrigger: '.fromLeft2' });
    }, []);

    { /* Entrada de bloques por derecha */ }
    const fromRight = useRef(null);
    const fromRight2 = useRef(null);

    useEffect(() => {
        gsap.to(fromRight.current, { duration: 1.5, transform: 'translateX(0)', opacity: 1, ease: 'circ.out', scrollTrigger: '.fromRight', });
    }, []);
    useEffect(() => {
        gsap.to(fromRight2.current, { duration: 1.5, transform: 'translateX(0)', opacity: 1, ease: 'circ.out', scrollTrigger: '.fromRight2', });
    }, []);

    return (
        <>
            <Navbar/>
            <HeaderCarousel/>

            <div className="quoteBackground">
                <img className="quoteBackgroundImg" src="/images/home/quoteBackground.png"/>
            </div>
            <div className="quoteContainer">
                <div className="quoteContainerLine"></div>
                <div ref={ lifestyle__title } className="lifestyle__title quote">"The lifestyle you have been waiting for"</div>
            </div>

            {/* Enclave */}
            <div className={ cx(home.introduction, 'section') }>
                <div className={ cx(home.introductionContent, 'content') }>
                    <div ref={ fromLeft } className={ cx(home.introductionImage, 'fromLeft') }>
                        <img src="/images/home/introductionImage.png"/>
                    </div>
                    <div ref={ fromRight } className={ cx(home.introductionDes, 'fromRight') }>
                        <div className={ cx(home.introductionDesTitle, 'title') }>THE ENCLAVE AT VISTAMAR</div>
                        <div className={ cx(home.introductionDesSubitle, 'subtitle') }>We offer unparalleled living with exclusive amenities that make a difference.</div>
                        <div className={ cx(home.introductionDesDes, 'des') }>The Enclave at Vistamar is an exclusive private residential community offering stunning ocean views of the Sea of Cortez. Located in the heart of Los Cabos, the area boasts beautiful desert landscapes and magnificent beaches.</div>
                        <a href="#" className={ cx(home.introductionDesTitle, home.introductionDesBtn) }>CONTACT</a>
                    </div>
                </div>
            </div>

            {/* Location */}
            <div className={ cx(home.location, 'section') }>
                <div className={ cx(home.locationContent, 'content') }>
                    <div ref={ location__title } className={ cx(home.locationTitle, 'location__title title') }>LOCATION AND LUXURY</div>
                    <div ref={ location__subtitle } className={ cx(home.locationSubtitle, 'location__subtitle subtitle') }>Our condos have been carefully designed to meet the highest quality of lifestyle for our residents, all with breathtaking ocean views.</div>
                </div>
            </div>
            <HomeCarousel/>
            {/*
            <div className={ cx(home.locationSlides) }>
                <div className={ cx(home.locationSlidesItem) }><img src="/images/home/location1.png"/></div>
                <div className={ cx(home.locationSlidesItem) }><img src="/images/home/location2.png"/></div>
                <div className={ cx(home.locationSlidesItem) }><img src="/images/home/location3.png"/></div>
            </div>
            */}

            {/* Our condos */}
            <div className={ cx(home.condos, 'section') }>
                <div className={ home.condosLine }></div>
                <div className={ cx(home.condosContent, 'content') }>
                    <div ref={ condos__title } className={ cx(home.condosTitle, 'condos__title title') }>OUR CONDOS</div>
                    <div ref={ condos__subtitle } className={ cx(home.condosSubtitle, 'condos__subtitle subtitle') }>Each condo is meticulously designed to offer the perfect blend of comfort, elegance and sophistication.</div>
                    <div className={ home.condosElement }>
                        <div className={ home.condosElementImg }>
                            <img src="/images/home/condos.png"/>
                        </div>
                        <div className={ home.condosElementDes }>
                            <div className={ home.condosElementDesTitle }>CONDOMINIUM</div>
                            <div className={ home.condosElementDesSubtitle }>Single-story ocean view condo.</div>
                            <div className={ home.condosElementDesList }>
                                <div className={ home.condosElementDesListElement }><span></span>2,339 Sq. Ft.</div>
                                <div className={ home.condosElementDesListElement }><span></span>3 Bedrooms</div>
                                <div className={ home.condosElementDesListElement }><span></span>3.5 Bathrooms</div>
                                <div className={ home.condosElementDesListElement }><span></span>Living Room</div>
                                <div className={ home.condosElementDesListElement }><span></span>Dining Room</div>
                                <div className={ home.condosElementDesListElement }><span></span>Kitchen</div>
                                <div className={ home.condosElementDesListElement }><span></span>Laundry Room</div>
                                <div className={ home.condosElementDesListElement }><span></span>Private Terrace</div>
                                <div className={ home.condosElementDesListElement }><span></span>2 Covered Parking Spaces</div>
                            </div>
                            <a href="#" className={ home.condosElementDesBtn }>FLOORPLAN</a>
                        </div>
                    </div>
                    <div className={ home.condosElement }>
                        <div className={ home.condosElementImg }>
                            <img src="/images/home/penthouse.png"/>
                        </div>
                        <div className={ cx(home.condosElementDes) }>
                            <div className={ home.condosElementDesTitle }>PENTHOUSE</div>
                            <div className={ home.condosElementDesSubtitle }>Two-story ocean view condo.</div>
                            <div className={ home.condosElementDesList }>
                                <div className={ home.condosElementDesListElement }><span></span>3,847 Sq. Ft.</div>
                                <div className={ home.condosElementDesListElement }><span></span>3 Bedrooms</div>
                                <div className={ home.condosElementDesListElement }><span></span>3 Bathrooms</div>
                                <div className={ home.condosElementDesListElement }><span></span>2 Half-Baths</div>
                                <div className={ home.condosElementDesListElement }><span></span>Living Room</div>
                                <div className={ home.condosElementDesListElement }><span></span>Dining Room</div>
                                <div className={ home.condosElementDesListElement }><span></span>Kitchen</div>
                                <div className={ home.condosElementDesListElement }><span></span>Laundry Room</div>
                                <div className={ home.condosElementDesListElement }><span></span>Storage Space</div>
                                <div className={ home.condosElementDesListElement }><span></span>Private Terrace</div>
                                <div className={ home.condosElementDesListElement }><span></span>Private Rooftop</div>
                                <div className={ home.condosElementDesListElement }><span></span>Private Plunge Pool</div>
                                <div className={ home.condosElementDesListElement }><span></span>Private Grill Area</div>
                                <div className={ home.condosElementDesListElement }><span></span>2 Covered Parking Spaces</div>
                            </div>
                            <a href="#" className={ home.condosElementDesBtn }>FLOORPLAN</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Brochure */}
            <div className={ cx(home.brochure, 'section') }>
                <picture>
                    <source type="image/webp" srcSet={ "/images/home/brochure.webp" } media="(min-width: 900px)"/>
                    <source type="image/png" srcSet={ "/images/home/brochure.png" } media="(min-width: 900px)"/>
                    <source type="image/webp" srcSet={ "/images/home/brochure900.webp" } media="(max-width:899px) and (min-width: 600px)"/>
                    <source type="image/png" srcSet={ "/images/home/brochure900.png" } media="(max-width:899px) and (min-width: 600px)"/>
                    <img src={ "/images/home/brochure600.png" } srcSet={ "/images/home/brochure600.webp" } alt="The enclave" className={ home.brochureBackground }/>
                </picture>
                <div className={ home.brochureDes }>
                    <img src="/images/home/logo_grey.png"/>
                    <div className={ cx(home.brochureDesTitle, 'title') }>HOME, SWEET, NEW HOME</div>
                    <div className={ cx(home.brochureDesSubtitle, 'subtitle') }>Download our brochure</div>
                    <a className={ home.brochureDesBtn } href="https://enclave.proyecta.com.mx/brochure.pdf" target="_blank">DOWNLOAD</a>
                </div>
            </div>

            {/* Los cabos */}
            <div className={ cx(home.cabos, 'section') }>
                <div className={ cx(home.cabosContent, 'content') }>
                    <div ref={ fromLeft2 } className={ cx(home.cabosImg, 'fromLeft2') }>
                        <img src="/images/home/losCabos.png"/>
                    </div>
                    <div ref={ fromRight2 } className={ cx(home.cabosDes, 'fromRight2') }>
                        <div className={ cx(home.cabosDesTitle, 'title') }>LOS CABOS</div>
                        <div className={ cx(home.cabosDesSubtitle, 'subtitle') }>MEXICO'S BEST KEPT SECRET.</div>
                        <div className={ cx(home.cabosDesT, 'des') }>Los Cabos is a magic land full of contrast, from breathtaking seas, to desert and mountains. Also known as <strong>“Land’s End”</strong> for being located at the tip of the southern Baja California Península.</div>
                        <div className={ cx(home.cabosDesT, 'des') }>Step into the extraordinary world of Los Cabos where the unparalleled natural beauty, biodiversity and range of amenities will appeal to any personality.</div>
                        <a href="#" className={ home.cabosDesBtn }>VISIT LOS CABOS</a>
                    </div>
                </div>
                <div className={ home.cabosDivision }></div>
            </div>

            <Footer/>
            <Copyrigth/>
        </>
    )
}