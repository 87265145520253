/* Librería generales de react */
import React from 'react';
import ReactDOM from 'react-dom/client';

/* Librería utilizada para tener más de una página */
import { BrowserRouter, Routes, Route } from "react-router-dom";

/* Importar las páginas */
import Home from './pages/home';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Home/> }/>
                {/* Error 404 */}
                <Route path="*" element={ <Home/> }/>
            </Routes>
        </BrowserRouter>
    </>
);