import Carousel from "react-multi-carousel"
import 'react-multi-carousel/lib/styles.css';
import home from "./../styles/home.module.css"

export function HeaderCarousel() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return (
          <Carousel 
            swipeable={ true }
            draggable={ true }
            infinite={ true }
            autoPlay={ true }
            autoPlaySpeed={ 3000 }
            removeArrowOnDeviceType={["mobile"]}
            responsive={responsive}>
            <div>
                <picture>
                    <source type="image/webp" srcSet={ "/images/home/header1920.webp" } media="(min-width:1400px)"/>
                    <source type="image/png" srcSet={ "/images/home/header1920.png" } media="(min-width:1400px)"/>
                    <source type="image/webp" srcSet={ "/images/home/header1500.webp" } media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/png" srcSet={ "/images/home/header1500.png" } media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/webp" srcSet={ "/images/home/header1200.webp" } media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/png" srcSet={ "/images/home/header1200.png" } media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/webp" srcSet={ "/images/home/header900.webp" } media="(max-width:899px) and (min-width: 600px)"/>
                    <source type="image/png" srcSet={ "/images/home/header900.png" } media="(max-width:899px) and (min-width: 600px)"/>
                    <img src={ "/images/home/header600.png" } srcSet={ "/images/home/header600.webp" } alt="The enclave" className={ home.homeHeaderImg }/>
                </picture>
            </div>
            <div>
                <picture>
                    <source type="image/webp" srcSet={ "/images/home/2header1920.webp" } media="(min-width:1400px)"/>
                    <source type="image/png" srcSet={ "/images/home/2header1920.png" } media="(min-width:1400px)"/>
                    <source type="image/webp" srcSet={ "/images/home/2header1500.webp" } media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/png" srcSet={ "/images/home/2header1500.png" } media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/webp" srcSet={ "/images/home/2header1200.webp" } media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/png" srcSet={ "/images/home/2header1200.png" } media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/webp" srcSet={ "/images/home/2header900.webp" } media="(max-width:899px) and (min-width: 600px)"/>
                    <source type="image/png" srcSet={ "/images/home/2header900.png" } media="(max-width:899px) and (min-width: 600px)"/>
                    <img src={ "/images/home/2header600.png" } srcSet={ "/images/home/2header600.webp" } alt="The enclave" className={ home.homeHeaderImg }/>
                </picture>
            </div>
            <div>
                <picture>
                    <source type="image/webp" srcSet={ "/images/home/4header1920.webp" } media="(min-width:1400px)"/>
                    <source type="image/png" srcSet={ "/images/home/4header1920.png" } media="(min-width:1400px)"/>
                    <source type="image/webp" srcSet={ "/images/home/4header1500.webp" } media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/png" srcSet={ "/images/home/4header1500.png" } media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/webp" srcSet={ "/images/home/4header1200.webp" } media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/png" srcSet={ "/images/home/4header1200.png" } media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/webp" srcSet={ "/images/home/4header900.webp" } media="(max-width:899px) and (min-width: 600px)"/>
                    <source type="image/png" srcSet={ "/images/home/4header900.png" } media="(max-width:899px) and (min-width: 600px)"/>
                    <img src={ "/images/home/4header600.png" } srcSet={ "/images/home/4header600.webp" } alt="The enclave" className={ home.homeHeaderImg }/>
                </picture>
            </div>
            <div>
                <picture>
                    <source type="image/webp" srcSet={ "/images/home/3header1920.webp" } media="(min-width:1400px)"/>
                    <source type="image/png" srcSet={ "/images/home/3header1920.png" } media="(min-width:1400px)"/>
                    <source type="image/webp" srcSet={ "/images/home/3header1500.webp" } media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/png" srcSet={ "/images/home/3header1500.png" } media="(max-width:1399px) and (min-width: 1200px)"/>
                    <source type="image/webp" srcSet={ "/images/home/3header1200.webp" } media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/png" srcSet={ "/images/home/3header1200.png" } media="(max-width:1199px) and (min-width: 900px)"/>
                    <source type="image/webp" srcSet={ "/images/home/3header900.webp" } media="(max-width:899px) and (min-width: 600px)"/>
                    <source type="image/png" srcSet={ "/images/home/3header900.png" } media="(max-width:899px) and (min-width: 600px)"/>
                    <img src={ "/images/home/3header600.png" } srcSet={ "/images/home/3header600.webp" } alt="The enclave" className={ home.homeHeaderImg }/>
                </picture>
            </div>
          </Carousel>
    )
}

export function HomeCarousel() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return (
          <Carousel
            className={ home.locationCarousel }
            swipeable={ true }
            draggable={ true }
            infinite={ false }
            responsive={responsive}>
            <div className={ home.locationCarouselEl }><img src="/images/home/location1.png"/></div>
            <div className={ home.locationCarouselEl }><img src="/images/home/location2.png"/></div>
            <div className={ home.locationCarouselEl }><img src="/images/home/location3.png"/></div>
            <div className={ home.locationCarouselEl }><img src="/images/home/location1.png"/></div>
            <div className={ home.locationCarouselEl }><img src="/images/home/location2.png"/></div>
            <div className={ home.locationCarouselEl }><img src="/images/home/location3.png"/></div>
          </Carousel>
    )
}