export function Navbar() {
    return (
        <div className="navbar">
            <div className="navbarContent content">
                {/* Logo */}
                <div className="navbarLogo">
                    <img className="navbarLogoImg" src="/images/logo.png"/>
                </div>
                {/* Botón de contacto */}
                <div className="navbarContact">
                    <a href="#" className="navbarContactButton">CONTACT</a>
                </div>
            </div>
        </div>
    )
}