/* Styles */
import home from "./../styles/home.module.css"
import cx from "classnames"

export function Footer() {
    return (
        <div className="footer section">
            <div className="footerContent content">
                <div className="footerImg">
                    <img className="footerImgLogo" src="/images/logoCompleto.png"/>
                    <div className="footerImgSeparator"></div>
                    <img className="footerImgEnclave" src="/images/footer/enclave.png"/>
                </div>
                <div className="footerContact">
                    <div className="footerContactTitle">Get in touch.</div>
                    <div className="footerContactSubtitle">For further information please contact us</div>
                    <a href="#" className="footerContactEl">
                        <img src="/images/footer/phone.svg"/>
                        <div className="footerContactElTitle">Línea 1</div>
                    </a>
                    <a href="#" className="footerContactEl">
                        <img src="/images/footer/phone.svg"/>
                        <div className="footerContactElTitle">Línea 2</div>
                    </a>
                    <a href="#" className="footerContactEl">
                        <img src="/images/footer/whatsapp.svg"/>
                        <div className="footerContactElTitle">Envíanos un mensaje</div>
                    </a>
                    <a href="#" className="footerContactEl">
                        <img src="/images/footer/map.svg"/>
                        <div className="footerContactElTitle">Ubícanos</div>
                    </a>
                    <a href="#" className="footerContactEl">
                        <img src="/images/footer/instagram.svg"/>
                        <div className="footerContactElTitle">/theenclave</div>
                    </a>
                    <a href="#" className="footerContactEl">
                        <img src="/images/footer/facebook.svg"/>
                        <div className="footerContactElTitle">/theenclave</div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export function Copyrigth() {
    return (
        <div className="copyright section">
            <div className="copyrightContent content">
                <div className="copyrightDivision"></div>
                THE ENCLAVE AT VISTAMAR. <a href="#">AVISO DE PRIVACIDAD.</a>
            </div>
        </div>
    )
}